import React from 'react';

import { LinkUI } from '@components/core/LinkUI';

import style from './FooterMenu.module.scss';

type IType = 'router' | 'default';

const initialState = [
    // { name: 'Оферта', link: '' },
    { name: 'Правила обучения', link: 'rules', type: 'router' as IType },
    { name: 'Политика конфиденциальности', link: 'privacy-policy', type: 'router' as IType  },
    { name: 'Требования к оборудованию', link: 'equipment-requirements', type: 'router' as IType  },
    { name: 'Гайд по личному кабинету', link: 'https://youtu.be/wUz9yYiBuOk', type: 'default' as IType  }
];

const FooterMenu = () => {
    const [menuList] = React.useState(initialState);

    return (
        <ul className={style.list}>
            {
                menuList.map((item, index) => (
                    <li key={index}>
                        <LinkUI to={item.link} typeLink={item.type}>{item.name}</LinkUI>
                    </li>
                ))
            }
        </ul>
    );
};

export default FooterMenu;
