import { FC, useMemo } from 'react';

import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';

import SelectBase from '@components/core/inputs/SelectStyled/SelectBase';
import Sidebar from '@components/methodist/Sidebar';
import Drawer from '@components/methodist/Sidebar/Drawer';
import { ICategory } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useGetUsersAllQuery } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import { useCategory } from '@views/methodist/Materials/MaterialsPage/materialsPage';

import style from './materials_tab.module.scss';

const cx = classNames;

interface IProps {
    className?: string;
    path?: string;
}

const emptyArray: ICategory[] = [];

const defaultMethodist = { value: false, label: 'Выберите методиста' };

const MaterialsSidebar: FC<IProps> = ({ className, path }) => {
    const { selectedMethodist } = useCategory();
    const navigate = useNavigate();

    const { categories } = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000', {
        selectFromResult: ({ data }) => ({
            categories: data ?? emptyArray
        })
    });

    const methodistsQueryData = useGetUsersAllQuery({ role: Role.Methodist, limit: 10000 });

    const methodistOptions = useMemo(() => {
        if (!methodistsQueryData.data) return [];

        const methodists = methodistsQueryData.data.users.map((user) => ({
            value: user.uuid,
            label: `${user.lastName} ${user.firstName} ${user.patronymic}`
        }));
        return [defaultMethodist, ...methodists];
    }, [methodistsQueryData.data]);

    const handleMethodist = (id: string | null) => {
        if (id) {
            navigate(`/materials/methodist/${id}`);
        } else if (categories) {
            navigate('/materials');
        }
    };

    return (
        <>
            <Sidebar>
                <Drawer className={style.drawer}>
                    <h2 className={style.title}>Учебные материалы</h2>

                    <SelectBase
                        className={style.select}
                        options={methodistOptions}
                        theme="dark"
                        name="methodist"
                        onChange={({ value }) => handleMethodist(value || null)}
                        value={methodistOptions.find(el => el.value === selectedMethodist) || defaultMethodist}
                    />

                    {categories ? (
                        <ul className={style.list}>
                            {categories.map((item, index) => (
                                <li key={index} className={style.item}>
                                    <div className={style.link_wrapper}>
                                        <NavLink to={`/materials/${item.uuid}`}
                                            className={({ isActive }) => cx(style.link_header, isActive && style['link--active'])}>
                                            {item.name}
                                        </NavLink>
                                        {/* <span className={style.count}>{item?.count ? item?.count : 0}</span> */}
                                    </div>
                                    {item?.children.length > 0 && (
                                        <ul className={style.content_list}>
                                            {item?.children.map((contentItem, indexContent) => (
                                                <li key={indexContent} className={style.content_item}>
                                                    <div className={style.link_wrapper}>
                                                        <NavLink
                                                            to={`/materials/${contentItem?.uuid}`}
                                                            className={({ isActive }) => cx(style.sub_link, isActive && style['link--active'])}
                                                        >
                                                            {contentItem?.name}
                                                        </NavLink>
                                                        <span className={style.count}>{contentItem?.count}</span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    ) : null}

                </Drawer>
            </Sidebar>
        </>
    );
};

export default MaterialsSidebar;
