import React, { useEffect, useState } from 'react';

import { PublicationContext } from 'centrifuge';
import { Outlet } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import { useWebSocketContext } from '@components/WebSocket/context';
import { addRoomToUserRecipients, checkAndReturnRoom } from '@helpers/chat';
import { useSound } from '@hooks/useSound';
import { useToggle } from '@hooks/useToggle.hook';
import { skipToken } from '@reduxjs/toolkit/query';
import { Uuid } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { chatApi, useCreateRoomMutation, useGetRoomsQuery } from '@store/chatApi/chatApi';
import { handleServiceMessage, resetRoomServices, selectCurrentChat, selectRooms, selectRoomsTotal } from '@store/features/chatSlice';
import { selectApiUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { MainEventMessage, MessageType, SocketMessage } from '@type/chat';
import { ENV } from '@type/common';


import { chatContext } from './index';

const currentEnv = process.env.REACT_APP_CURRENT_ENV;
const SERVICE_USER = currentEnv === ENV.prod ? 'f7511ab2-9fe0-44ef-b46c-3bebf005e1f4' : '';

const ROOMS_LIMIT = 15;

export const ChatProvider: React.FC = ( ) => {
    const [isOpen, { on, off }] = useToggle();

    const dispatch = useAppDispatch();
    const { play } = useSound('chat');

    // states

    const [roomsOffset, setRoomsOffset] = useState(0);
    const [roomId, setRoomId] = useState<string | null>(null);

    // нужно для определения новых сообщений в комнатах при запуске приложения
    const [initRooms, setInitRoom] = useState(false);
    const [roomsWithNewMessages, setRoomsWithNewMessages] = useState<string[]>([]);


    // selectors
    const user = useAppSelector(selectApiUser);
    const roomsData = useAppSelector(selectRooms);
    const roomsTotal = useAppSelector(selectRoomsTotal);
    const currentRoom = useAppSelector((state) => selectCurrentChat(state, roomId));

    // api
    // данные из запроса не использовать, группы хранятся в редакс
    const serviceRoomsUnuse = useGetRoomsQuery(user ? { offset: roomsOffset, limit: ROOMS_LIMIT } : skipToken, { skip: roomsTotal < roomsOffset });
    const [createRoom] = useCreateRoomMutation();
    const groupsData = useGetGroupsTreeQuery(user && user.role !== Role.Student ? '' : skipToken);

    const canRoomsUpdate = roomsTotal > roomsOffset;

    const handleRoomsUpdate = () => {
        setRoomsOffset(roomsOffset + ROOMS_LIMIT);
    };

    const onGetMessage = (ctx: PublicationContext) => {
        const socketMessage = ctx.data as SocketMessage;
        const recipient = socketMessage.recipients.find(item => item.uuid === user?.uuid);
        const message: MainEventMessage = {
            uuidSender: socketMessage.uuidSender,
            type: 'New message',
            payload: socketMessage.payload.text ?? '',
            uuidRoom: recipient?.uuid ?? socketMessage.recipients[0].uuidRoom as string
        };
        if (!currentRoom && message.uuidSender !== user?.uuid) {
            dispatch(handleServiceMessage(message));
        }
        if (socketMessage.recipients[0].uuidRoom !== roomId) {
            play();
        }
    };

    // ws
    const { sendMessage, subscription } = useWebSocketContext();

    useEffect(() => {
        if (!isOpen) {
            setRoomId(null);
            setRoomsOffset(0);
        } else {
            dispatch(chatApi.util.invalidateTags(['Rooms']));
        }
    }, [isOpen]);

    useEffect(() => {
        localStorage.setItem('selected-room-id', JSON.stringify(roomId));
        dispatch(chatApi.util.invalidateTags(['Messages']));
        if (roomId !== null) {
            setRoomsWithNewMessages(prev => prev.filter(id => id !== roomId));
        }
    }, [roomId]);

    useEffect(() => {
        window.addEventListener('storage', (event) => {
            if (event.key === 'selected-room-id') {
                const value = event.newValue ? JSON.parse(event.newValue) : null;
                if (value) {
                    dispatch(resetRoomServices(value));
                }
            }
        });
    }, []);


    // проверят айдишники последних сообщений в local storage и сверяет с новыми только один раз,
    // чтобы вычислить в каких комнатах новые сообщения
    useEffect(() => {
        const lsKey = `rooms-of-${user?.uuid}`;
        const prevLastMessagesJSON = localStorage.getItem(lsKey);

        if (!initRooms && roomsData.length) {
            const prevLastMessages = prevLastMessagesJSON ? JSON.parse(prevLastMessagesJSON) : {};
            const data = roomsData.filter((room) => {
                if (!(room.uuid in prevLastMessages)) {
                    return room.previewMessage.uuidSender !== user?.uuid && room.previewMessage?.uuidSender;
                } else if (prevLastMessages[room.uuid] !== (room?.previewMessage?.uuid || '')) {
                    return room.previewMessage.uuidSender !== user?.uuid && room.previewMessage?.uuidSender;
                }
                return false;
            });

            setRoomsWithNewMessages(data.map(({ uuid }) => uuid));

            setInitRoom(true);
        }
    }, [roomsData]);

    useEffect(() => {
        if (!user) {
            setInitRoom(false);
            setRoomsWithNewMessages([]);
            setRoomId(null);
        }
    }, [user]);


    const writeToUser = async (userId: Uuid) => {
        on();
        const room = checkAndReturnRoom(roomsData, userId);
        if (!room) {
            const newRoom = await createRoom({ fromUser: user!.uuid, toUser: userId }).unwrap();
            serviceRoomsUnuse.refetch();
            setRoomId(newRoom.uuid);
        } else {
            setRoomId(room.uuid);
        }
    };

    const sendMessageByGroup = async (
        users: Uuid[],
        text: string,
        uuidsFile?: string[] | null
    ) => {
        sendMessage({
            type: uuidsFile ? MessageType.File : MessageType.Text,
            recipients: addRoomToUserRecipients(users, roomsData),
            text,
            uuidsFile,
            uuid: uuidV4()
        });
    };

    const sendServiceMessage = (text: string) => {
        if (serviceRoomsUnuse.data && SERVICE_USER) {
            sendMessage({
                type: MessageType.Text,
                recipients: addRoomToUserRecipients([SERVICE_USER], serviceRoomsUnuse.data.rooms),
                text,
                uuid: uuidV4()
            });
        }
    };

    useEffect(() => {
        if (subscription) {
            subscription.on('publication', onGetMessage);
        }

        return () => {
            if (subscription) {
                subscription.off('publication', onGetMessage);
            }
        };
    }, [subscription]);

    return (
        <chatContext.Provider value={{
            roomId,
            setRoomId,
            isOpen,
            onOpen: on,
            onClose: off,
            writeToUser,
            rooms: roomsData,
            currentRoom,
            groupsData: groupsData.data,
            roomsWithNewMessages,
            sendMessageByGroup,
            sendServiceMessage,
            canRoomsUpdate,
            handleRoomsUpdate,
            isRoomsLoading: serviceRoomsUnuse.isFetching
        }}>
            <Outlet />
        </chatContext.Provider>
    );
};
