import { Role } from '@store/Roles';
// import { User } from '@type/users';

export type Uuid = string

export interface withStamps {
    createdAt: string;
    updatedAt: string;
    deletedAt?: string;
}

export interface ICategory {
    uuid: string;
    uuidParent: string;
    name: string;
    count?: number;
    children: ICategory[];
}

export interface IDiscipline {
    uuid: string;
    name: string;
    picture: IStorageFile;
    uuidCategory: string;
    status: boolean;
    teachers: Array<User>;
    doubles: Array<User>;
    metodists: Array<User>;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    children?: IDiscipline[];
    score?: number;
}

export interface IDisciplineStatus {
    uuid: Uuid,
    status: boolean
}

export interface GetDisciplinePagination {
    limit: number,
    offset: number,
    search?: string
}

export type CreateCourseData = {
    uuidCategory: string;
    name: string;
    picture: IStorageFile;
    status: boolean;
    uuidsTeacher: Array<string>;
    uuidsMetodist: Array<string>;
}

export type UpdateCourseData = {
    uuid: string;
    name: string;
    picture: IStorageFile;
    status: boolean;
    uuidsTeacher: Array<string>;
    uuidsMetodist: Array<string>;
    uuidsDouble: Array<string>
}

export interface JwtUser {
    Uuid: string;
    UserRole: Role;
    Status: string;
    FirstName: string;
    LastName: string;
    Patronymic: string;
    RegistrationStatus: string;
    RememberMe: boolean;
    exp: number;
    Email?: string;
    LastSeen?: string;
    name?: string;
}

export interface User {
    uuid: string,
    groupUUID: string[],
    firstName: string,
    fullName?: string,
    lastName: string,
    patronymic: string,
    email: string,
    role: Role,
    status: string,
    lastActivity: string,
    registrationStatus: string,
    vkontakte: string,
    telegram: string,
    picture?: IStorageFile,
    phone: string,
    country: string,
    city: string,
    createdAt: string
}

export enum fileTypes {
    Public = 'PUBLIC',
    Private = 'PRIVATE',
}

export interface IStorageFile {
    uuid: string,
    path: string,
    title: string,
    extension: string,
    size: number,
    userUuid: string,
    createdAt: string,
    updatedAt: string,
    type: fileTypes
}

export type uploadFileData = {
    file: string,
    meta: {
        filename: string,
        type: fileTypes,
        contentType?: string, // MIME type
    },
    toastDisabled?: boolean
}

export enum EnumMaterialsType {
    Link = 'Link',
    File = 'File',
    Page = 'Page',
    Task = 'Task',
    Test = 'Test',
    Video = 'VideoLecture',
    Lecture = 'LectureBlock'
}

export type MaterialType =
    EnumMaterialsType.Link
    | EnumMaterialsType.File
    | EnumMaterialsType.Page
    | EnumMaterialsType.Task
    | EnumMaterialsType.Video
    | EnumMaterialsType.Lecture
    | EnumMaterialsType.Test;


export type Task = {
    type: EnumMaterialsType.Task,
    uuid: Uuid,
    name: string,
    content: string,
    maxRating: number,
    uuidsFile: Uuid[],
    files: IStorageFile[],
    uuidParent: Uuid,
    disciplineUuid?: Uuid,
    blockedGroups: string[]
    position: number,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
    needPlagiarismCheck: boolean
    minUniquenessPercent: number
}

export type GetTaskDataResponse = Omit<Task, 'type'>

export type CreateTaskData = Omit<Task, 'type' | 'uuid' | 'files' | 'createdAt' | 'updatedAt' | 'deletedAt'>
export type CreateTaskDataResponse = Omit<Task, 'type' | 'files' | 'uuidParent' | 'createdAt' | 'updatedAt' | 'deletedAt'>

export type UpdateTaskData = Omit<Task, 'type' | 'uuidParent' | 'files' | 'position' | 'createdAt' | 'updatedAt' | 'deletedAt'>
export type UpdateTaskDataResponse = UpdateTaskData


//TEST
export type Test = {
    type: EnumMaterialsType.Test,
    uuid: string,
    sectionUuid: string,
    disciplineUuid: string,
    name: string,
    description: string,
    maximumRating: number,
    numberAttempts: number,
    needEvaluate: boolean,
    accessRestricted: string[],
    createdAt: string,
    updatedAt: string,
    deletedAt: string
}


//
export type Page = {
    type: EnumMaterialsType.Page,
    uuid: string,
    name: string,
    content: string,
    uuidParent?: string,
    uuidSection?: string,
    blockedGroups?: string[],
    position?: number,
    createdAt: string,
    updatedAt: string,
    deletedAt: string
}

export type GetPageDataResponse = Omit<Page, 'type'>

export type CreatePageData = Omit<Page, 'type' | 'uuid' | 'createdAt' | 'updatedAt' | 'deletedAt'>
export type CreatePageDataResponse = Omit<Page, 'type'>

export type UpdatePageData = Omit<Page, 'type' | 'createdAt' | 'updatedAt' | 'deletedAt'>
export type UpdatePageDataResponse = UpdateFileData

export type File = {
    type: EnumMaterialsType.File,
    uuid: string,
    name: string,
    file: IStorageFile,
    uuidFile: string
    uuidParent?: string,
    uuidSection?: string,
    position?: number,
    createdAt: string,
    updatedAt: string,
    deletedAt: string

}
export type GetFileDataResponse = Omit<File, 'type' | 'uuidFile'>

export type CreateFileData = Omit<File, 'type' | 'uuid' | 'file' | 'createdAt' | 'updatedAt' | 'deletedAt'>
export type CreateFileDataResponse = Omit<File, 'type' | 'file' | 'createdAt' | 'updatedAt' | 'deletedAt'>

export type UpdateFileData = Omit<File, 'type' | 'uuidParent' | 'file' | 'createdAt' | 'updatedAt' | 'deletedAt'>
export type UpdateFileDataResponse = UpdateFileData

export type Link = {
    type: EnumMaterialsType.Link,
    uuid: string
    name: string,
    link: string,
    uuidParent?: string,
    uuidSection?: string,
    position?: number
    createdAt: string,
    updatedAt: string,
    deletedAt: string
}

export type CreateLinkData = Omit<Link, 'type' | 'uuid' | 'createdAt' | 'updatedAt' | 'deletedAt'>
export type UpdateLinkData = Omit<Link, 'type' | 'uuidParent' | 'createdAt' | 'updatedAt' | 'deletedAt'>

export type Materials = Array<Link | File | Page | Task | VideoBlock | LectureBlock | Test>
export type DisciplineMaterials = Array<Link | File | Page>
export type MaterialsItem = Link | File | Page | Task | VideoBlock | LectureBlock | Test

export type PositionMaterialsItem = {
    uuid: string,
    position: number
}

export type UpdatePositionsMaterialsListData = {
    parentUuid?: string;
    positions: PositionMaterialsItem[]
}

export type Section = {
    uuid: string,
    name: string,
    description: string,
    published: boolean,
    position: number,
    blockedGroups: string[],
    file?: IStorageFile, // privet Vlad, ya popravil.
    uuidDiscipline: string,
    materials?: Materials,
    createdAt: string,
    updatedAt: string,
    deletedAt: string
}

export type UpdateSectionData = Omit<Section,
'position'
| 'uuidDiscipline'
| 'createdAt'
| 'updatedAt'
| 'deletedAt'>

export type CreateSectionData = Omit<Section,
'uuid'
| 'uuidDiscipline'
| 'createdAt'
| 'updatedAt'
| 'deletedAt'>


export type PositionItem = {
    uuid: string,
    position: number
}

export type PositionsItemsList = PositionItem[]


export type UpdatePositionsSectionListData = {
    positions: PositionItem[]
}

export type DeleteSectionData = {
    uuids: string[]
}

export interface SectionsListData {
    sections: Section[];
}


export type UpdateCourseCategory = {
    uuid: string;
    uuidCategory: string;
}

export type VideoBlock = {
    type: EnumMaterialsType.Video,
    uuid: string
    name: string,
    uuidParent: string,
    videoLectures: VideoLectureResponce[],
    createdAt: string,
    updatedAt: string,
    deletedAt: string
}

export type VideoBlockResponse = {
    uuid: string
    name: string,
    uuidParent: string,
    videoLectures: VideoLectureResponce[],
    createdAt: string,
    updatedAt: string,
    deletedAt: string
}

export type VideoBlockCreateData = {
    name: string,
    uuidParent: string,
    videoLectures: VideoLectureCreateData[]
}

export type VideoBlockCreateResponse = {
    uuid: string,
    name: string,
    uuidParent: string,
    videoLectures: VideoLectureCreateResponse[]
}

export type VideoLectureCreateData = {
    name: string,
    uuidParent: string,
    linkFile: string,
    uuidsFile: string[],
    position: number
}

export type VideoLectureCreateResponse = {
    uuid: string,
    name: string,
    uuidParent: string,
    linkFile: string,
    uuidsFile: string[]
}

export type VideoLectureResponce = {
    uuid: string
    name: string,
    uuidParent: string,
    linkFile: string,
    files: IStorageFile[],
    createdAt: string,
    updatedAt: string,
    position: number
}

export type LectureTopic = {
    uuid: string;
    name: string;
    content: string;
    uuidBlock: string;
    lectures: Lecture[];
    position: number;
    createdAt: string;
    updatedAt: string;
}

export type LectureBlock = {
    type: EnumMaterialsType.Lecture,
    uuid: string;
    name: string;
    uuidParent: string;
    topics: LectureTopic[];
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export type ChapterLectureBlockProgress = {
    uuidChapter: string,
    status: boolean
}

export type LectureBlockProgress = {
    chapters: ChapterLectureBlockProgress[]
}

export type LectureBlocksChapter = {
    uuid: string,
    name: string
}

export type LectureBlocksLecture = {
    uuid: string,
    name: string,
    chapters: LectureBlocksChapter[],
    disabled?: boolean
}

export type LectureBlocksTopic = {
    uuid: string,
    name: string,
    lectures: LectureBlocksLecture[]
}

export type LectureBlocksContent = {
    uuid: string;
    name: string;
    topics: LectureBlocksTopic[]
}

export interface LectureBlocksList {
    blocks: LectureBlock[];
}

export type LectureBlockCreateData = {
    name: string;
    uuidParent: string;
}

export type CreateLectureTopicRequest = {
    name: string;
    uuidBlock: string;
    position: number;
}

export type UpdateLectureTopicRequest = {
    uuid: string;
    name: string;
}

export type UpdatePositionsLectureTopicData = {
    positions: PositionItem[],
    parentUuid: string
}

export interface LectureTopicsList {
    topics: LectureTopic[];
}

export type Lecture = {
    uuid: string;
    name: string;
    chapters: LectureChapter[];
    uuidTopic: string;
    position: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export interface LectureList {
    lectures: Lecture[];
}

export type CreateLectureRequest = {
    name: string;
    uuidTopic: string;
    position: number;
}

export type UpdateLectureRequest = {
    uuid: string;
    name: string;
    uuidTopic: string;
}

export type DeleteLectureRequest = {
    uuid: string;
    uuidTopic: string;
}

export type LectureChapter = {
    uuid: string;
    name: string;
    content: string;
    uuidLecture: string;
    position: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export type CreateLectureChapterRequest = {
    name: string;
    content: string;
    uuidLecture: string;
    position: number;
}

export type UpdateLectureChapterRequest = {
    uuid: string;
    name: string;
    content: string;
}

export interface GroupTree {
    UUID: string,
    parentUUID: string,
    name: string,
    isInherit: boolean,
    countUsers: number,
    childs: GroupTree[]
}

export interface GroupData {
    UUID: string,
    parentUUID: string,
    name: string,
    isInherit: boolean
}

export type CreateGroupDataRequest = Omit<GroupData, 'UUID'>

export type UsersImportData = {
    file: string,
    group: string
}

export type UsersImportResponse = {
    count: number,
    file: string,
    status: boolean
}

export type ResetPassData = {
    token: string,
    newPassword: string
}

export type UsersChangeGroupData = {
    uuidGroup: string;
    uuids: string[];
}

export enum SessionTypes {
    Semester1 = 'Semester1',
    Semester2 = 'Semester2',
    Semester3 = 'Semester3',
    Semester4 = 'Semester4',
    Semester5 = 'Semester5',
    Semester6 = 'Semester6',
    Semester7 = 'Semester7',
    Semester8 = 'Semester8'
}

export enum SessionTypesWithLocale {
    Semester1 = 'Семестр 1',
    Semester2 = 'Семестр 2',
    Semester3 = 'Семестр 3',
    Semester4 = 'Семестр 4',
    Semester5 = 'Семестр 5',
    Semester6 = 'Семестр 6',
    Semester7 = 'Семестр 7',
    Semester8 = 'Семестр 8'
}

export enum SessionTypesWithLocaleReverse {
    Semester1 = '1 cеместр',
    Semester2 = '2 cеместр',
    Semester3 = '3 cеместр',
    Semester4 = '4 cеместр',
    Semester5 = '5 cеместр',
    Semester6 = '6 cеместр',
    Semester7 = '7 cеместр',
    Semester8 = '8 cеместр'
}

export enum CloseTypes {
    Assessment = 'Assessment',
    AssessmentWithScore = 'AssessmentWithScore',
    Exam = 'Exam',
    NoMark = 'NoMark',
}

export enum CloseTypesWithLocale {
    Assessment = 'Зачет',
    AssessmentWithScore = 'Зачет с оценкой',
    Exam = 'Экзамен',
    NoMark = 'Без оценки'
}

export type GroupDiscipline = {
    name?: string,
    disciplineUUID: string,
    session: SessionTypes,
    closeType: CloseTypes,
    startDate: string,
    progress: number
    picture?: IStorageFile;
    score?: number,
    deadline: string
}

export type SubscribeDiscipline = {
    disciplineUUID: string,
    session: SessionTypes,
    closeType: CloseTypes,
    startDate: string,
    progress?: number
}

export interface SubscribeDisciplinesRequest {
    groupUUID: string,
    disciplines: SubscribeDiscipline[],
    noToast?: boolean;
}

export interface SubscribeAdditionalDisciplinesRequest {
    uuidUser: string[];
    disciplines: SubscribeDiscipline[];
}

export interface IGroupDiscipline {
    groupUUID: string[],
}

export type GroupDisciplineR = {
    disciplineUUID: string,
    session: SessionTypes,
    closeType: CloseTypes,
    deadline: string,
    progress: number,
    score: number
}

export interface SubscribedDisciplinesResponse {
    disciplines: GroupDisciplineR[],
}

export type TSubscribeItem = {
    uuid: string;
    session: SessionTypes,
    closeType: CloseTypes,
    startDate: string,
    disabled?: boolean,
}

export type TTermsItem = {
    uuid: string;
    session?: SessionTypes,
    closeType?: CloseTypes,
    deadlineStart?: string,
    deadlineEnd?: string
}

export type TSubscribeMassValues = Omit<TSubscribeItem, 'uuid'>
export type TestData = {
    uuid: string,
    disciplineUuid: string,
    sectionUuid: string,
    name: string,
    description: string,
    maximumRating: number,
    timeLimit: number,
    numberAttempts: number,
    needEvaluate: boolean,
    accessRestricted: string[],
    questions: string[],
    questionsNum: number,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
    testStarted?: boolean
}

export type TestCreateData = {
    uuidDiscipline: string,
    uuidSection: string,
    name: string,
    description: string,
    maximumRating: number,
    timeLimit: number,
    numberAttempts: number,
    needEvaluate: boolean,
    accessRestricted: string[]
}

export type TestUpdateData = {
    uuid: string,
    testData: TestCreateData
}

export type TestQuestionAnswerSimple = {
    isCorrect: boolean,
    text: string
}

export type TestQuestionAnswerMultiple = {
    text: string,
    weight: number
}

export type TestQuestionAnswerToMatch = {
    subQuestion: string,
    subAnswer: string
}

export type TestQuestionAnswerCombined = {
    answerSimple: TestQuestionAnswerSimple,
    answerMultiple: TestQuestionAnswerMultiple,
    answerToMatch: TestQuestionAnswerToMatch
}

export type TestQuestion = {
    uuid: string,
    testUuid: string,
    question: string,
    type: string

}

export type TestQuestionWithAnswers = {
    question: TestQuestion,
    answers: TestQuestionAnswerCombined[]
}

export type QuestionsFilter = {
    limit: number;
    offset: number;
    search: string;
}

export type TestGetQuestionsFromBank = {
    filters: QuestionsFilter,
    uuid: string;
}

export type TestGetQuestionsFromBankForQuestion = {
    filters: QuestionsFilter,
    uuid: string,
    questionId: string
}

export type TestWithQuestions = {
    test: TestData,
    questions: TestQuestionWithAnswers[]
}

export type TestAddQuestionsRequest = {
    testUuid: string,
    questionsUuids: string[]
}

export type TestDeleteQuestionsRequest = {
    testUuid: string,
    questionIds: string[]
}

export type TestDeleteRequest = {
    disciplineUuid: string,
    testUuid: string
}

export type TestSetRandomQuestions = {
    testUuid: string,
    questionsNum: number
}

export type StudentTestQuestionWithAnswers = {
    question: TestQuestion,
    answers: StudentTestQuestionAnswerCombined[]
}

export type StudentTestQuestionAnswerToMatch = {
    subQuestion: string,
    subAnswer: string,
    uuid: string
}

export type TestImportRequest = {
    data: string,
    testUuid: string
}

export type DeleteAttemptParams = {
    uuids: Uuid[]
}

export type StudentTestQuestionAnswerCombined = {
    answerSimple: StudentTestQuestionAnswerSimpleAndMultiple,
    answerMultiple: StudentTestQuestionAnswerSimpleAndMultiple,
    answerToMatch: StudentTestQuestionAnswerToMatch
}

export type StudentTestQuestionAnswerSimpleAndMultiple = {
    uuid: string,
    text: string
}

export enum EnumTestAnswer {
    SINGLE = 'SINGLE',
    MULTIPLY = 'MULTIPLE',
    TO_MATCH = 'TO_MATCH'
}

export interface PassStudentTestInfo {
    uuid: string,
    disciplineUuid: string,
    sectionUuid: string,
    name: string,
    description: string,
    maximumRating: number,
    timeLimit: number,
    numberAttempts: number,
    needEvaluate: boolean,
    accessRestricted: string[],
    questions: string[],
    questionsNum: number,
    testStarted: boolean,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
}

export interface PassStudentTest {
    test: PassStudentTestInfo,
    question: StudentTestQuestionWithAnswers[],
    beginningDate: string,
    uuidAttempt: string,
    passedTime: number
}


export interface passTestRequest {
    uuidQuestion: string;
    type: EnumTestAnswer;
    uuidsAnswers?: string[];
    toMatchAnswers?: {
        uuidAnswer: string;
        subAnswer: string;
    }[];
}

export interface passTestRequestPayload {
    test: passTestRequest[],
    uuidTest: string
}

export enum EnumEventType {
    Webinar = 'WEBINAR',
    Unknown = 'UNKNOWN'
}

export interface GetTestRequest {
    uuidTest: string;
    uuidAuth: string;
    uuidDevice: string;
}

export interface FinishTestRequest {
    uuidTest: string;
    uuidDiscipline: string;
    uuidAttempt: string;
    questions: passTestRequest[];
}

export interface FinishTestResponse {
    grade: number;
}

export enum EnumEventStatus {
    Completed = 'COMPLETED',
    NotStarted = 'NOT_STARTED',
    Started = 'STARTED'
}

export interface PassEventRequest {
    limit: number,
    offset: number
}

export interface CalendarEventRequest {
    fromDate: string;
    toDate: string;
    name?: string;
    groups?: string[];
    discipline?: string;
    teacher?: string;
}

export interface Event {
    eventUuid: string,
    name: string,
    disciplineUuid: string,
    type: string,
    startEventAt: string,
    duration: number,
    disciplineName: string,
    teacherInfo?: {
        uuid: string,
        firstName: string,
        lastName: string,
        patronymic: string
    },
    status: EnumEventStatus,
    files: IStorageFile[],
    excludedGroups: string[],
    linkToRecords: string[],
    links: string[]
}

export interface CreateEvent {
    name: string,
    disciplineUuid: string,
    teacherUuid: string,
    type: string,
    startEventAt: string,
    duration: number,
    files: string[],
    links: string[],
    excludedGroups: string[],
    linkToRecords: string[]
}

export interface EditEvent {
    name: string,
    disciplineUuid: string,
    teacherUuid: string,
    type: EnumEventType.Webinar,
    startEventAt: string,
    duration: number,
    files: string[],
    links: string[],
    linkToRecords: string[],
    excludedGroups: string[],
    eventUuid: string
}

export interface EventsByUuidRequest {
    uuids: string[];
}

export interface EventsByUuid extends Event {
    eventUuid: string,
    excludedGroups: string[]
}

export interface IDisciplinesAll {
    limit?: number;
    offset?: number;
    search?: string;
}


export type TDeadline = {
    deadlineStart: string
    deadlineEnd: string
}

export type TMaterialsDeadline = {
    disciplineUUID?: string,
    materialUUID: string,
    deadline: TDeadline
}


export interface IGroupDeadlinesApiResponse {
    disciplineUUID: string;
    materialUUID: string;
    deadline: {
        deadlineEnd: string;
        deadlineStart: string;
    };
}

export interface IStudentDeadlinesApiResponse {
    userUUID: string;
    material: {
        disciplineUUID: string;
        materialUUID: string;
        deadline: {
            deadlineStart: string;
            deadlineEnd: string;
        }
    }[];

}

export interface IGroupDeadlinesSliceData {
    disciplineUUID: string,
    materials: Omit<IGroupDeadlinesApiResponse, 'deadline'>[],
}

export interface ISetGroupDeadlinesApiRequest {
    groupUUID: string,
    materials: Omit<IGroupDeadlinesApiResponse, 'deadline'>[],
    deadline: TDeadline
}

export interface ISetUserDeadlinesApiRequest {
    userUUID: string[],
    groupUUID: string,
    materials: Omit<IGroupDeadlinesApiResponse, 'deadline'>[],
    deadline: TDeadline
}

export interface IGetUserDeadlinesRequest {
    groupUUID: string,
    userUUID: string[],
}


export type GetCompletedChaptersData = {
    uuidBlock: string;
    uuidUser: string
}

export type CreateEntryCompletedChapterData = {
    uuidBlock: string;
    uuidChapter: string;
    uuidUser: string
}

export type CompletedChapterResponse = {
    uuid: string,
    uuidBlock: string,
    uuidChapter: string,
    uuidUser: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string
}


export interface TestAttemptsInfo {
    hasActiveAttempt: boolean,
    usersAttemptsCount: number,
    totalNumberOfAttempts: number,
    testTimeLimit: number,
    lastAttemptCreatedDate: string,
    passedTime: number,
    gradesForPreviousAttempts: Grade[]
}

export interface TestAttemptsCard {
    uuid: string,
    date: string,
    mark: number,
    isBest: boolean,
    isInProgress: boolean
}

export interface PassAuthenticationRequest {
    uuidAuth: string,
    uuidUser: string,
    uuidMaterial: string,
    uuidAttempt?: string
    uuidDevice: string;
}

export interface PassAuthenticationResponse {
    uuid: string,
    uuidAuth: string,
    uuidUser: string,
    uuidMaterial: string,
    uuidAttempt: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string
}

export interface AuthenticationPayload {
    uuidAuthentication: string,
    uuidTest: string
}

export interface Grade {
    uuid: string;
    uuidDiscipline: string;
    uuidGroup?: string;
    uuidMaterial: string;
    uuidTeacher?: string;
    uuidUser: string;
    file: IStorageFile;
    grade: number;
    uuidAttempt: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    studentComment: string;
    teacherReview: string;
    checked: boolean;
}

export interface IAntiplagiarism {
    CheckServiceResults: object[],
    Summary:{
        IsSuspicious: boolean,
        BaseScore:{
            Legal: number,
            Plagiarism: number,
            SelfCite :number,
            Unknown: number
        }
    }
}
export interface IAntiplagiat {
    antiplagiatMustCheck: boolean
    antiplagiatFile: IStorageFile,
    antiplagiarism:IAntiplagiarism
    passedUniqueness: boolean
}

export interface GradeTeacher extends IAntiplagiat  {
    uuid: string,
    discipline: {
        uuid: string,
        name: string,
        uuidCategory: string,
        status: boolean,
        createdAt: string,
        updatedAt: string,
        deletedAt: string
    },

    test: TestData,
    task: GetTaskDataResponse,
    user: User,
    teacher: User,
    file: IStorageFile,
    grade: number,
    uuidAttempt: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
    studentComment: string,
    teacherReview: string,
    checked: boolean,
    retakeAvailable?: boolean,
    timeForEstimation: number
}

export interface GradesCountsForTeacher {
    toCheck: number,
    checked: number,
    asDouble: number
}

export interface GetCheckedTasksForTeacherRequest {
    uuidsDiscipline: string[],
    limit: number,
    offset: number
}

export interface CheckedTasksForTeacher {
    tasks: CheckedTaskForTeacher[],
    total: number
}

export interface CheckedTaskForTeacher {
    uuidUser: string,
    nameUser: string,
    uuidDiscipline: string,
    nameDiscipline: string,
    grade: number,
    submittedAt: string,
    uuidTask: string,
    nameTask: string
}

export interface GetUncheckedTasksForTeacherRequest {
    uuidsDiscipline: string[]
}

export interface UncheckedTasksForTeacher {
    tasks: {
        uuidDiscipline: string,
        nameDiscipline: string,
        uuidTask: string,
        nameTask: string,
        grades: {
            uuidUser: string,
            nameUser: string,
            gradeCreatedAt: string
        }[],
        total: number
    }[],
    expiring: number,
    expired: number
}

export interface GetUncheckedGradesInTaskForTeacherRequest {
    uuidTask: string,
    limit: number,
    offset: number
}

export interface UncheckedGradesInTaskForTeacher  {
    uuidDiscipline: string,
    nameDiscipline: string,
    uuidTask: string,
    nameTask: string,
    grades: UncheckedGrades[],
    total: number
}

export interface UncheckedGrades  {
    uuidUser: string,
    nameUser: string,
    gradeCreatedAt: string
}

export interface GradeTeacherSubmit {
    uuid: Uuid;
    grade?: number;
    review?: string;
    retakeAvailable: boolean;
    uuidTeacher: Uuid;
    type?: 'retake';
}

export interface GradeTeacherSubmitResponse {
    uuid: string,
    uuidDiscipline: string,
    uuidMaterial: string,
    uuidUser: string,
    uuidFile: string,
    grade: number,
    uuidAttempt: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
    'studentComment': string,
    teacherReview: string,
    checked: boolean
}

export interface GradeTaskResponse {
    uuid: string;
    uuidAuth: string;
    uuidUser: string;
    uuidMaterial: string;
    uuidAttempt: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export interface IGradeCreateRequest {
    uuidDiscipline: string
    uuidMaterial: string
    uuidUser: string
    uuidFile: string,
    studentComment: string,
    uuidAuth: string
}

export interface GetGradesRequest {
    uuidsDiscipline?: string[],
    uuidsUser?: string[],
    uuidsMaterial?: string[],
    uuidsGroup?: string[]
}

export interface DeleteGradesRequest {
    uuidsAttempt?: Uuid[];
}

export interface AuthenticationAttempt {
    uuidTest: string,
    uuidAttempt: string
}

export interface UpdateUserSocialsRequest {
    vkontakte: string;
    telegram: string;
    uuidUser?: string;
}


export interface UpdatePasswordRequest {
    password: string;
    newPassword: string;
}

export interface UpdateMainData {
    uuid: string;
    firstName: string;
    lastName: string;
    patronymic: string;
    phone: string;
    countryId: number;
    cityId: number;
}

export interface IUraitResponse {
    status: string;
    url: string;
}

export interface IUraitRequest {
    time: string;
    email: string;
    first_name: string;
    last_name: string;
    second_name?: string;
    role_id?: string;
    redirect_url: string;
}

export interface IGetArchiveResponse {
    uuid: string,
    uuidUser: string;
    file: IStorageFile,
    loaded: boolean
    createdAt: string
}

export interface IGetUsersArchiveRequest {
    uuidsGroup?: string[],
    firstNoteDate?: string,
    lastNoteDate?: string,
    search?: string,
    limit?: number,
    offset?: number
}

export type IGetUsersArchiveResponse = {
    users: {
        createdAt: string;
        firstNoteDate: string;
        lastNoteDate: string;
        uuidUser: string;
    }[],
    total: number
}


export interface IArchiveNoticeResponse {
    needDownloadCount: string;
}

export interface IBiblioClubResponse {
    domain: string;
    sign: string;
    time: number;
    user_uuid: string;
}

export interface IBiblioClubRequest {
    time: number;
    user_uuid: string;
}

// skills

export type WithUUID<T> = T & { uuid:string };
export type WithTimeStamps<T> = T & { createdAt: string, updatedAt: string };
export type ResponseMessage<T> = WithUUID<WithTimeStamps<T>>

export interface CreateSkillRequestMessage {
    name: string;
    content: string;
    uuidPicture?: string;
    uuidGroup?: string;
    uuidProgramme?: string;
    uuidsDiscipline?: string[]
}

export interface BaseSkill {
    uuid?: string;
    name: string;
    content: string;
    uuidPicture?: string;
}
export interface Skill extends BaseSkill {
    group: ResponseMessage<{ name: string }>,
    programme: ResponseMessage<{ name: string }>,
    disciplines: ResponseMessage<SkillDiscipline>[]
}

// skills disciplines

export interface BaseSkillDiscipline {
    name:string,
    semester:string
}
export interface SkillDiscipline extends BaseSkillDiscipline {
    uuidsPresets: string[],
    uuidsRealDisciplines: string[],
    uuidsSkills: string[],
    uuidCategory?: string,
}

// presets

export interface BasePreset {
    name: string;
}

export interface Preset {
    name: string,
    uuidsDiscipline: string[],
    uuidsGroup?: string[]
}

export type SavePresetResponseMessage = ResponseMessage<{name:string}>
export interface GetPresetsResponseMessage {
    presets: ResponseMessage<Preset>[]
}


export interface StudentSkill {
    uuid: string,
    name: string,
    content: string,
    uuidPicture?: string,
    createdAt: string,
    updatedAt: string,
    group: {
        uuid: string,
        name: string,
        createdAt: string,
        updatedAt: string
    },
    programme: {
        uuid: string,
        name: string,
        createdAt: string,
        updatedAt: string
    },
    progress: number,
    disciplines: [
        {
            uuid: string,
            name: string,
            semester: string,
            createdAt: string,
            updatedAt: string,
            passed: true,
            subscribed?: boolean,
        }
    ]
}

export interface ISkillsResponse {
    skills: StudentSkill[],
    overallProgress: number
}


