import React, { FC } from 'react';

import { useChat } from '@components/Chat/context';
import Button from '@components/core/inputs/Button';
import UserAvatar from '@components/UserAvatar';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { User }  from '@type/users';

import style from './TeachingStaffItem.module.scss';

interface ITeachingStaffItem {
    user: User;
    onClose?: () => void;
    isDouble?: boolean
}

const TeachingStaffItem: FC<ITeachingStaffItem> = ({ user, onClose, isDouble }) => {
    const { role, firstName, lastName, patronymic, picture, uuid, isChatAvailable } = user;
    const me = useAppSelector(selectApiUser);

    const { writeToUser } = useChat();

    const handleClickWrite = () => {
        onClose?.();
        writeToUser(uuid);
    };

    return (
        <li className={style.item}>
            <UserAvatar name={firstName} lastName={lastName} avatar={picture?.path} className={style.avatar} />
            <div className={style.user_info}>
                <span className={style.role}>
                    {
                        role === Role.Methodist ? 'Методист' : (
                            role === Role.Teacher ? isDouble ? 'Преподаватель-дублер' : 'Преподаватель' : ''
                        )
                    }
                </span>
                <p className={style.name}>{`${lastName} ${firstName} ${patronymic}`}</p>
                {
                    isChatAvailable && uuid !== me?.uuid && <Button btnType={'clear'} className={style.chat_btn} onClick={handleClickWrite}>Написать в чат</Button>
                }
            </div>
        </li>
    );
};

export default TeachingStaffItem;
