import { FC } from 'react';

import { ReactComponent as FailIcon } from '@assets/icons/antiplagiarism-fail.svg';
import { ReactComponent as SuccessIcon } from '@assets/icons/antiplagiarism-success.svg';
import { ReactComponent as SuspiciousIcon } from '@assets/icons/antiplagiarism-suspicious.svg';
import Button from '@components/core/inputs/Button';
import { Tooltip } from '@components/Tooltip';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { IAntiplagiarism, IStorageFile } from '@store/api/apiTypes';

import style from './Antiplagiarism.module.scss';

type Props = {
    result: IAntiplagiarism;
    file?: IStorageFile;
    passedUniqueness: boolean;
    col?: number;
    isStudent?: boolean;
};

export const Antiplagiarism: FC<Props> = ({ col, result, file, passedUniqueness, isStudent = false }) => {
    const progressData = [
        { name: 'Оригинальность', value: result.Summary.BaseScore.Unknown, color: '#ddedfa' },
        { name: 'Совпадения', value: result.Summary.BaseScore.Plagiarism, color: '#EE7A4B' },
        { name: 'Цитирования', value: result.Summary.BaseScore.Legal, color: '#DAE9A1' },
        { name: 'Самоцитирования', value: result.Summary.BaseScore.SelfCite, color: '#989B9B' }
    ];

    const smUp = useBreakpoint('sm', 'down');
    const countCol = col || smUp ? 2 : 4;
    return (
        <div className={style.antiplagiarism}>
            <div className={style.header}>
                <div className={style.name}>Антиплагиат</div>
                {passedUniqueness ? (
                    <Tooltip title="Документ успешно прошел проверку">
                        <SuccessIcon />
                    </Tooltip>
                ) : (
                    <Tooltip title="Документ не прошел проверку">
                        <FailIcon />
                    </Tooltip>
                )}

                {result?.Summary.IsSuspicious && (
                    <Tooltip title="Подозрительный документ">
                        <SuspiciousIcon />
                    </Tooltip>
                )}
            </div>
            <div className={style.board} style={{ gridTemplateColumns: `repeat(${countCol}, 140px)` }}>
                {progressData.map((item, index) => (
                    <div key={index} className={style.boardItem}>
                        <div className={style.boardItemName}>{item.name}</div>
                        <div className={style.result}>
                            <div>{item.value || 0}%</div>
                            <div className={style.progressBar}>
                                <div className={style.progress} style={{ width: `${item.value}%`, backgroundColor: item.color }}></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={style.sources}>{`Источников: ${result?.CheckServiceResults.length}`}</div>
            {!isStudent && (
                <Button download btnType="download" href={file?.path}>
                    Смотреть результаты
                </Button>
            )}
        </div>
    );
};
