import { createContext, useContext } from 'react';

import { GroupTree, Uuid } from '@store/api/apiTypes';
import { Room } from '@type/chat';

interface ChatContext {
    roomId: string | null;
    setRoomId: (roomId: string | null) => void;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    writeToUser: (userId: Uuid) => void;
    rooms: Room[] | null;
    currentRoom: Room | null;
    groupsData: GroupTree | undefined;
    roomsWithNewMessages: string[];
    sendMessageByGroup: (
        users: Uuid[],
        message: string,
        uuidsFile?: string[] | null
    ) => void;
    sendServiceMessage: (message: string) => void;
    canRoomsUpdate: boolean;
    handleRoomsUpdate: () => void;
    isRoomsLoading: boolean;
}

export const chatContext = createContext<ChatContext | null>(null);

export function useChat() {
    const ctx = useContext(chatContext);
    if (ctx === null) throw new Error('chatContext must be defined');
    return ctx;
}
