import { FC, useEffect, useState } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { useChat } from '@components/Chat/context';
import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import Switcher from '@components/core/inputs/Switcher';
import { NewLoader } from '@components/core/NewLoader';
import Paper from '@components/core/styledWrappers/Paper';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { getPaths } from '@helpers/findNestedPath';
import { findRootCurrentGroup, makeGroupTreeFlat } from '@helpers/groupTree';
import { getFormattedDate } from '@helpers/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupTree } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import {
    useChangeUserChatStatusByUuidMutation,
    useChangeUserStatusByUuidMutation,
    useResetUserPasswordMutation,
    useUpdateUserSocialsMutation
} from '@store/api/usersApi';
import { changeSoundOn, selectSoundOn } from '@store/features/settingsLSSlice';
import { selectApiUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Role, roleNames } from '@store/Roles';
import { UserStatus } from '@type/users';

import { UserDeleteModal } from '../UserDeleteModal';
import { UserProfileDataModal } from '../UserProfileDataModal';
import { useUser } from './../UserProfile';
import style from './user_profile_info.module.scss';


interface IProps {
    className?: string;
}

const socialsSchema = yup.object({
    vk: yup.string(),
    telegram: yup.string(),
    recordBookNumber: yup.string(),
    role: yup.string()
});

type TUserProfileSocialsForm = yup.InferType<typeof socialsSchema>;

const UserProfileInfo: FC<IProps> = () => {
    const dispatch = useAppDispatch();

    const user = useUser();
    const { writeToUser } = useChat();
    const apiUser = useAppSelector(selectApiUser);
    const groupsData = useGetGroupsTreeQuery('');
    const [updateSocials] = useUpdateUserSocialsMutation();
    const [dropPassword] = useResetUserPasswordMutation();
    const [changeStatus] = useChangeUserStatusByUuidMutation();
    const [changeChatStatus] = useChangeUserChatStatusByUuidMutation();

    const [showUserSettingsModal, setUserSettingsModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [pathTo, setPathTo] = useState<{uuid: string, name: string}[]>([]);
    const [rootGroupUuid, setRootGroupUuid] = useState<string>('');
    const [isShowBtn, setIsShowBtn] = useState(false);

    const soundOn = useAppSelector(selectSoundOn);

    const userRole = roleNames[user.role];
    const isStudent = user.role === Role.Student;

    const socialsMethods = useForm<TUserProfileSocialsForm>({
        resolver: yupResolver(socialsSchema),
        mode: 'onBlur',
        defaultValues: {
            vk: user.vkontakte,
            telegram: user.telegram
        }
    });

    const { watch, handleSubmit: socialsHandleSubmit, reset } = socialsMethods;

    const watchSVk = watch('vk');
    const watchTelegram = watch('telegram');

    const onSocialsSubmit = (formData: TUserProfileSocialsForm) => {
        updateSocials({
            vkontakte: formData.vk || '',
            telegram: formData.telegram || '',
            uuidUser: user.uuid
        });
    };

    const onStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const status = event.target.checked ? UserStatus.Active : UserStatus.Blocked;

        changeStatus({
            uuids: [user.uuid],
            status
        });
    };

    const onChatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const status = event.target.checked ? true : false;

        changeChatStatus({
            uuids: [user.uuid],
            status
        });
    };

    useEffect(() => {
        setIsShowBtn(watchSVk !== user.vkontakte || watchTelegram !== user.telegram);
    }, [watchSVk, watchTelegram]);

    useEffect(() => {
        if (groupsData.data && groupsData.isSuccess) {
            //Получение текущей группы студента
            const currentGroup = makeGroupTreeFlat(groupsData.data.childs).find(g => g.UUID === user.groupUUID[0]);
            // Нахождение рут группы студента
            currentGroup && setRootGroupUuid(findRootCurrentGroup(currentGroup, groupsData.data).UUID);
        }

        reset({
            telegram: user.telegram,
            vk: user.vkontakte
        });
    }, [user]);

    // Создание хлебных крошек на основании текущей и рутовой групп студента
    useEffect(() => {
        if (groupsData.isSuccess && user.role === Role.Student) {
            const pathToGroup = getPaths<GroupTree, 'childs', 'UUID'>(
                groupsData.data.childs,
                'childs',
                'UUID',
                (group) => group.UUID === (user.groupUUID[0] ?? rootGroupUuid)
            );

            setPathTo(pathToGroup ?? []);
        } else {
            setPathTo([]);
        }
    }, [groupsData.isSuccess, user.groupUUID[0], rootGroupUuid]);

    const handleSoundOff = () => {
        dispatch(changeSoundOn());
    };

    if (groupsData.isFetching) return <NewLoader />;

    return (
        <>
            <Paper className={style.profile}>
                <div className={style.title_wrapper}>
                    <div className={style.profile_header}>
                        <div className={style.profile_photo_container}>
                            {
                                user.picture?.path
                                    ?
                                    <img src={user.picture?.path} alt="Profile" />
                                    :
                                    <>
                                        <div className={style.profile_photo_name}>
                                            {`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
                                        </div>
                                        <div className={style.profile_photo_icon}>
                                            <IconUI typeIcon="camera" />
                                        </div>
                                    </>
                            }
                        </div>
                        <div className={style.header_info}>
                            {
                                userRole
                                    &&
                                <div className={cx(style.status, style.status_success)}>
                                    {userRole}
                                </div>
                            }
                            <h1>
                                {`${user.lastName} ${user.firstName} ${user.patronymic}`}
                            </h1>
                            <div className={style.profile_breadcrumps}>
                                {
                                    pathTo.length ?
                                        <div>
                                            <Breadcrumbs>
                                                {
                                                    pathTo.map((group) => <span key={group.uuid}>{group.name}</span>)
                                                }
                                            </Breadcrumbs>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        {apiUser?.uuid !== user.uuid && (
                            <Button btnType="outline" style={{ minWidth: 200 }} onClick={() => writeToUser(user.uuid)}>
                                Отправить сообщение
                            </Button>
                        )}
                    </div>
                </div>
                <div className={style.profile_main_info}>
                    <div className={style.section}>
                        <h2 className={style.subtitle}>
                            Личные данные
                        </h2>
                        <div className={style.section_net}>
                            <div>Логин (email)</div>
                            <div>{user.email || '-'}</div>
                            <div>Телефон</div>
                            <div>{user.phone || '-'}</div>
                            <div>Страна</div>
                            <div>{user.country || '-'}</div>
                            <div>Город</div>
                            <div>{user.city || '-'}</div>
                            {
                                isStudent && <>
                                    <div>Номер зачетки</div>
                                    <div>{user.recordBookNumber || '-'}</div>
                                </>
                            }
                        </div>
                        <RoleCheck roles={Role.Methodist}>
                            <div className={style.section_link} onClick={() => setUserSettingsModal(true)}>
                                Изменить личные данные
                            </div>
                        </RoleCheck>

                        <div className={style.socialNetworks}>
                            <h2 className={style.subtitle}>Cоцсети</h2>

                            <FormProvider {...socialsMethods}>
                                <div className={style.field}>
                                    <div className={style.label}>Аккаунт VK</div>
                                    <div className={style.content}>
                                        <InputControl
                                            classNameWrapper={style.input}
                                            name="vk"
                                            placeholder="Аккаунт VK"
                                            disabled={!checkRole(Role.Methodist) && user.uuid !== apiUser?.uuid}
                                        />
                                    </div>
                                </div>

                                <div className={style.field}>
                                    <div className={style.label}>Аккаунт Telegram</div>
                                    <div className={style.content}>
                                        <InputControl
                                            classNameWrapper={style.input}
                                            name="telegram"
                                            placeholder="Аккаунт Telegram"
                                            disabled={!checkRole(Role.Methodist) && user.uuid !== apiUser?.uuid}
                                        />
                                    </div>
                                </div>
                                

                                {userRole && <div className={cx(style.field, style.role)}>
                                    <div className={style.label}>Роль</div>
                                    <div className={style.content}>
                                        <InputControl classNameWrapper={style.input} name="role" disabled value={userRole} />
                                    </div>
                                </div>}

                                {isShowBtn &&
                                    <Button btnType="success" className={style.save_btn} onClick={socialsHandleSubmit(onSocialsSubmit)} >
                                        Сохранить изменения
                                    </Button>
                                }
                            </FormProvider>
                        </div>
                        {apiUser?.uuid === user.uuid && (
                            <div className={style.socialNetworks}>
                                <h2 className={style.subtitle}>Уведомления</h2>
                                <div className={style.notice}>
                                    <p>Звуковые уведомления</p>
                                    <Switcher value={soundOn} onChange={handleSoundOff} />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={style.section}>
                        <RoleCheck roles={Role.Methodist}>
                            <div className={style.section_link} onClick={() => dropPassword([user.uuid])}>
                                Выслать временный пароль
                            </div>
                            <div className={style.section_caption}>
                                Пользователь получит уведомление о смене пароля
                            </div>
                            <div className={style.userActivity}>
                                <div className={style.caption}>Активный пользователь</div>
                                <div className={style.activitySwitcher}>
                                    <Switcher disabled={apiUser?.uuid === user.uuid} value={user.status === UserStatus.Active} onChange={onStatusChange} />
                                </div>
                            </div>
                            <div className={style.chatAvailability}>
                                <div className={style.caption}>Может получать сообщения в чате</div>
                                <div className={style.activitySwitcher}>
                                    <Switcher disabled={user.role === Role.Student} value={user.isChatAvailable} onChange={onChatChange} />
                                </div>
                            </div>
                        </RoleCheck>
                        <div className={style.activity}>
                            <div>
                                {/* TODO: заменить дату создания записи о юзере на дату первого входа юзера в кабинет */}
                                Первый вход в кабинет: { getFormattedDate(
                                    user.lastActivity && user.createdAt ? user.createdAt.replace(/ \+0000 UTC/, '') : '',
                                    'Данные отсутствуют'
                                )}
                            </div>
                            <div>
                                Последний вход в кабинет: { getFormattedDate(
                                    user.lastActivity.replace(/ \+0000 UTC/, ''),
                                    'Пользователь ни разу не заходил в кабинет'
                                )}
                            </div>
                        </div>
                        {
                            (apiUser?.uuid !== user.uuid) && checkRole(Role.Methodist) && <div className={style.remove}>
                                <IconUI typeIcon="basket" />
                                <div className={style.remove_link} onClick={() => setDeleteModal(true)}>
                                    Удалить аккаунт
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Paper>

            <UserProfileDataModal
                defaultValues={{
                    lastName: user.lastName,
                    firstName: user.firstName,
                    patronymic: user.patronymic,
                    countryId: user.CountryID,
                    recordBookNumber: user.recordBookNumber,
                    phone: user.phone,
                    cityId: user.CityID
                }}
                isOpen={showUserSettingsModal}
                onClose={() => setUserSettingsModal(false)}
                userRole={user.role}
            />

            <UserDeleteModal
                isOpen={deleteModal}
                onClose={() => setDeleteModal(false) } />

        </>
    );
};

export default UserProfileInfo;
