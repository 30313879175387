import { FC } from 'react';

import clsx from 'classnames';

import { Antiplagiarism } from '@components/Antiplagiarism';
import IconUI from '@components/core/IconUI';
import { InlineControls } from '@components/core/InlineControls';
import Button from '@components/core/inputs/Button';
import Paper from '@components/core/styledWrappers/Paper';
import { Linkify } from '@components/Linkify';
import { dateConvert } from '@helpers/date';
import { GradeTeacher } from '@store/api/apiTypes';
import GradeReview from '@views/teacher/GradesTask/GradeReview';

import style from './CompletedTask.module.scss';

export interface ICompleteTaskProps {
    attempt: GradeTeacher,
    index?: number;
    methodistPreview?: boolean;
    onDelete?: () => void;
    handleRetake?: (grade: GradeTeacher) => void;
}

const CompletedTask:FC<ICompleteTaskProps> = ({
    attempt,
    index,
    methodistPreview,
    onDelete,
    handleRetake
}) => {
    const timer = attempt.timeForEstimation ? new Date(attempt.timeForEstimation * 1000).toISOString().slice(11, attempt.timeForEstimation < 300 ? 19 : 16) : 0;

    const getLabel = () => {
        if (methodistPreview) return `Попытка ${index}`;
        return `Ваш ответ${index && index !== 1 ? ` #${index}` : ''}`;
    };
    return (
        <Paper fullWidth={true} className={style.root}>
            <div className={style.row}>
                <h2>{getLabel()}</h2>
                {methodistPreview && (
                    <div className={style.controls}>
                        {
                            timer ? <div className={style.timer}>
                                <IconUI typeIcon="clock" />
                                {timer} ч.
                            </div> : null
                        }

                        <div className={style.delete}>
                            <InlineControls onDelete={onDelete} />
                        </div>
                    </div>

                )}
            </div>

            <div className={style.content}>
                <div className={style.file_upload}>
                    <div>
                        <Button btnType="download" typeIcon="clip-green" download href={attempt.file?.path || ''}>
                            <p>{attempt.file?.title || ''}</p>
                        </Button>
                    </div>
                    <span className={style.date}>
                        Отправлено на проверку:
                        { dateConvert(attempt.createdAt.replace(/ \+0000 UTC/, '')).toFormat(' dd.LL.yyyy, в HH:mm') }
                    </span>

                </div>
                {attempt.antiplagiarism && (
                    <Antiplagiarism isStudent result={attempt.antiplagiarism} file={attempt.antiplagiatFile} passedUniqueness={attempt.passedUniqueness} />
                )}

                <div>
                    {
                        attempt.studentComment && (
                            <>
                                <h3>Комментарий</h3>
                                <p className={clsx(style.gradeComment, !attempt.checked && style.margin)}>
                                    {
                                        <Linkify content={attempt.studentComment} />
                                    }
                                </p>
                            </>
                        )
                    }

                </div>
                {
                    attempt.checked && <GradeReview
                        grade={attempt.grade}
                        teacherReview={attempt.teacherReview}
                        teacher={attempt.teacher}
                        updatedAt={attempt.updatedAt}
                        handleRetake={handleRetake && (() => handleRetake(attempt))}
                    />
                }
            </div>
        </Paper>
    );
};

export default CompletedTask;
