/***
 * Вытащить query параметры из ссылки
 */
export const extractParameters = (url: string): Record<string, string | null> => {
    const parsedUrl = new URL(url);
    const params = new URLSearchParams(parsedUrl.search);

    const parameters: Record<string, string | null> = {};
    params.forEach((value: string, key: string) => {
        parameters[key] = value;
    });

    return parameters;
};
/***
 * Удаляем все html теги типа <a> </a>
 */

export const removeHtmlTags = (html: string): string => {
    let cleanedHtml = html.replace(/<\/?[^>]+(>|$)/g, '');
    cleanedHtml = cleanedHtml.replace(/[\n\r]/g, '');
    return cleanedHtml;
};

/***
 * Вытягиваем домен из ссылки
 */
export const extractDomain = (url: string): string => url.replace(/^(https?:\/\/)?(www\.)?/, '').split('/')[0];
/***
 * Проверка является ли линк одним из билбилотечных 
 */
export const isSSOLink = (url: string): boolean => ['urait.ru', 'biblioclub.ru'].includes(extractDomain(url));

/***
 * Является ли переданная строка <a> тегом
 */
export const isALink = (fullMatch: string): boolean => fullMatch.startsWith('<a');

/***
 * Является ли переданная строка a нодой
 */
export const isATagDOMNode = (name: string): boolean => name === 'a';

/***
 * Является ли переданная строка text нодой
 */
export const isTextDOMNode = (type: string): boolean => type === 'text';