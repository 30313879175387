import React, { useMemo } from 'react';

import clsx from 'classnames';

import { findLinks, replaceLinksInString } from '@components/Linkify/helper';
import { SSOLink } from '@components/Linkify/SSOLink';
import { isSSOLink } from '@helpers/url';
import MarkdownPreviewLib from '@uiw/react-markdown-preview';

import style from './MarkdownPreview.module.scss';

interface MarkdownPreviewProps {
    source?: string | null;
    className?: string;
    isNewTabLinks?: boolean;
}

export const MarkdownPreview: React.FC<MarkdownPreviewProps> = ({ source, className, isNewTabLinks }) => {

    const transformedMedia      = useMemo(() => replaceLinksInString(source ?? ''), [source]);
    const rehypeRewiteConfig    = useMemo(() => {
        return (node: any) => {

            if (node.tagName === 'a') {
                node.properties.target = '_blank';
                node.properties.rel = 'noreferrer';
            }
        };
    }, []);

    const components: any = {
        //@ts-ignore
        a: ({ node, children }) => {

            if (isSSOLink(node.properties?.href as string )) {

                const link = findLinks(node.properties?.href as string)[0];
                return <SSOLink domain={link.domain} href={link.domain} value={link.value} />;
            }

            return <a href={node.properties?.href as string ?? ''} target={isNewTabLinks ? '_blank' : '_self'} rel="noreferrer">{children}</a>;
        }
    };

    if (!source) return null;

    return (<>
        {
            transformedMedia.map(item => {

                if (typeof item === 'string') {
                    return <MarkdownPreviewLib
                        source={item}
                        components={components}
                        className={clsx(style.markdown, className)}
                        rehypeRewrite={rehypeRewiteConfig} />;
                }

                if (typeof item == 'object') {

                    return item;
                }
            })
        }
    </>
    );
};
