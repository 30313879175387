import React, { FC, useEffect, useRef, useState } from 'react';

import clsx from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useChat } from '@components/Chat/context';
import NoMessages from '@components/Chat/parts/NoMessages';
import Loader from '@components/core/Loader';
import { getCompanion } from '@helpers/chat';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { MessageType, PreviewMessage } from '@type/chat';

import Contact from '../Contact';
import style from './ContactList.module.scss';


const ChatContactList:FC = () => {
    const mdDown = useBreakpoint('md', 'down');
    const { rooms, setRoomId, roomId, roomsWithNewMessages, canRoomsUpdate, handleRoomsUpdate, isRoomsLoading } = useChat();

    const getLastMessage = (lastMessage: PreviewMessage): string | null => {
        switch (lastMessage.type) {
            case MessageType.Text:
                return lastMessage.text;
            case MessageType.File:
                return lastMessage.files?.[0]?.title;
            default:
                return null;
        }
    };

    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const [wrapperHeight, setWrapperHeight] = useState(0);

    useEffect(() => {
        if (wrapperRef.current) {
            const element = wrapperRef.current;

            const resizeHandler = () => {
                setWrapperHeight(element.clientHeight);
            };

            new ResizeObserver(resizeHandler).observe(element);
        }
    }, [wrapperRef.current]);

    const handleScroll = (event: any) => {
        const scrollBottom = event.target.scrollTop + event.target.clientHeight;
        const scrollKoef = scrollBottom / event.target.scrollHeight;

        if (scrollKoef > 0.8 && canRoomsUpdate && !isRoomsLoading) {
            handleRoomsUpdate();
        }
    };

    return (
        <div className={style.wrapper} ref={wrapperRef}>
            <div id="parent-scroll-for-chat-messages" style={{ maxHeight: wrapperHeight }} className={style.contactList} ref={scrollContainerRef}
                onScroll={handleScroll}
            >
                {!rooms || !rooms.length ? (
                    <div className={style['no-data']}>
                        {mdDown && <NoMessages type="contacts" />}
                    </div>
                ) : (
                    <InfiniteScroll
                        dataLength={rooms.length}
                        next={() => null}
                        hasMore={canRoomsUpdate}
                        loader={<Loader />}
                        scrollableTarget="parent-scroll-for-chat-messages"
                        className={style.container}
                    >
                        {rooms.map(room => {
                            const companion = getCompanion(room.users);

                            if (!companion) return null;

                            return (
                                <div key={room.uuid} className={clsx(style.item, roomId === room.uuid && style.selected)}>
                                    <Contact
                                        user={companion}
                                        regular
                                        lastMessage={getLastMessage(room.previewMessage)}
                                        uuidSender={room.previewMessage.uuidSender}
                                        lastTime={room.previewMessage.createdAt}
                                        onSelect={() => setRoomId(room.uuid)}
                                        uuid={room.uuid}
                                        isNew={roomsWithNewMessages.includes(room.uuid)}
                                    />
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
};

export default ChatContactList;
