import { toast } from 'react-toastify';

import { setRoomsToStore } from '@store/features/chatSlice';
import { store } from '@store/store';
import { CreateRoom, CreateRoomResponse, MessagesRequest, MessagesResponse, RoomsRequest, RoomsResponse, SendMessageByGroup } from '@type/chat';

import { chatBaseApi } from './chatBaseApi';

export const chatApi = chatBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getRooms: build.query<RoomsResponse, RoomsRequest>({
            query: ({ offset, limit }) => ({
                url: 'rooms/get',
                method: 'POST',
                body: { offset, limit }
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const me = store.getState().userSlice.apiUser;
                    const result = await queryFulfilled;
                    dispatch(setRoomsToStore({
                        rooms: result.data.rooms,
                        me,
                        offset: args.offset,
                        total: result.data.total
                    }));
                } catch (error) {
                    console.log('🚀 ~ file: chatApi.ts ~ line 21 ~ onQueryStarted ~ error', error);
                }
            },
            providesTags: ['Rooms']
        }),
        createRoom: build.mutation<CreateRoomResponse, CreateRoom>({
            query: ({ fromUser, toUser } ) => ({
                url: 'rooms/',
                method: 'POST',
                body: {
                    uuidsUser: [fromUser, toUser]
                }
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка при создании чата');
                }
            },
            invalidatesTags: ['Rooms']
        }),
        getRoomMessages: build.query<MessagesResponse, MessagesRequest>({
            query: (params) => ({
                url: 'rooms/one',
                method: 'POST',
                body: params
            }),
            transformResponse: (result: MessagesResponse) => {
                return {
                    ...result,
                    messages: result.messages
                        ? result.messages.map(message => ({ ...message, id: message.uuid || '' }))
                        : null
                };
            },
            providesTags: ['Messages']
        }),
        sendMessage: build.mutation<any, { body: SendMessageByGroup, toastDisabled?: boolean }>({
            query(data) {
                return {
                    url: 'rooms/message',
                    method: 'POST',
                    body: data.body
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                if (!args.toastDisabled) {
                    toast.promise(queryFulfilled, {
                        success: 'Сообщение отправлено',
                        error: 'Ошибка отправки сообщения'
                    });
                }
            },
            invalidatesTags: ['Rooms']
        })
    })
});

export const {
    useGetRoomsQuery,
    useCreateRoomMutation,
    useSendMessageMutation
} = chatApi;
