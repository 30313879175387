import React, { FC, useEffect, useRef, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import { useChat } from '@components/Chat/context';
import { FileDropWrapper } from '@components/Chat/parts/FileDropWrapper';
import Loader from '@components/core/Loader';
import { addRoomToLSWithPayload, checkIsOtherDate, checkOwnMessage } from '@helpers/chat';
import { selectMessages } from '@store/features/chatSlice';
import { useAppSelector } from '@store/hooks';
import { MessageType, StorageMessage } from '@type/chat';


import MessageFile from '../Message/File';
import Message from '../Message/Message';
import MessageText from '../Message/Text';
import MessageDateSeparator from '../MessagesSeparator';
import { useMessages } from './context';
import style from './Messages.module.scss';

const CHAT_LIMIT = 30;

const Messages:FC = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [lastMessageUuid, setLastMessageUuid] = useState<string>();
    const [isImageLoaded, setImageLoaded] = useState(false);
    const { roomId } = useChat();
    const { checkAndRequestChunk, canUpdate } = useMessages();

    const [wrapperHeight, setWrapperHeight] = useState(0);

    useEffect(() => {
        if (wrapperRef.current) {
            const element = wrapperRef.current;

            const resizeHandler = () => {
                setWrapperHeight(element.clientHeight);
            };

            new ResizeObserver(resizeHandler).observe(element);
        }
    }, [wrapperRef.current]);



    const data = useAppSelector((state) => selectMessages(state, roomId));

    const renderMessageContent = (message: StorageMessage) => {
        switch (message.type) {
            case MessageType.Text:
                return (
                    <Message own={checkOwnMessage(message)}>
                        <MessageText
                            time={message.createdAt}
                            own={checkOwnMessage(message)}
                            loading={message.loading}
                        >
                            { message.text }
                        </MessageText>
                    </Message>
                );
            case MessageType.File:
                return (
                    <MessageFile message={message} onImageLoaded={() => setImageLoaded(!isImageLoaded)} />
                );
        }
    };

    useEffect(() => {
        if (scrollContainerRef.current && lastMessageUuid !== data?.[0]?.id) {
            const element = scrollContainerRef.current;
            element.scrollTop = element.scrollHeight;
            setLastMessageUuid(data?.[0]?.id);
        }

        // сохранение uuid самого нового сообщения в LS
        if (data[0]) {
            addRoomToLSWithPayload(data[0].uuidRoom, data[0]?.uuid || '');
        }

    }, [data?.[0]?.id, isImageLoaded]);

    const pendingRef = useRef(false);

    const handleScroll = async (event: any) => {
        const scrollKoef = event.target.scrollTop / event.target.scrollHeight;

        if (scrollKoef < 0.2 && canUpdate && !pendingRef.current) {
            pendingRef.current = true;
            const lastScrollTop = event.target.scrollTop;
            await checkAndRequestChunk();
            pendingRef.current = false;
            const isIOS = /iPad|iPhone|iPod|Mac/.test(navigator.userAgent);
            if (isIOS) {
                const scrollOffsetKoef = Math.round(data.length / CHAT_LIMIT);
                event.target.scrollTop = (event.target.scrollHeight / (scrollOffsetKoef + 1)) + lastScrollTop;
            }
        }
    };

    return (
        <div className={style.wrapper} ref={wrapperRef}>
            <FileDropWrapper className={style['drop-wrapper']}>
                <div
                    id="parent-scroll-for-chat-messages"
                    style={{ maxHeight: wrapperHeight }}
                    className={style['parent-container']}
                    ref={scrollContainerRef}
                    onScroll={handleScroll}
                >
                    <InfiniteScroll
                        dataLength={data.length}
                        next={() => null}
                        inverse={true}
                        hasMore={canUpdate}
                        loader={<Loader />}
                        scrollableTarget="parent-scroll-for-chat-messages"
                        className={style.container}
                    >
                        {data.map((message, index) => {
                            const nextItem = data[index + 1];

                            const isNewDate = checkIsOtherDate(message.createdAt, nextItem?.createdAt) && !(!nextItem && canUpdate);
                            return (
                                <React.Fragment key={message.id}>
                                    {renderMessageContent(message)}
                                    {isNewDate && <MessageDateSeparator date={message.createdAt} />}
                                </React.Fragment>
                            );
                        })}
                    </InfiniteScroll>
                </div>
            </FileDropWrapper>
        </div>
    );
};

export default Messages;
