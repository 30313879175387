import React from 'react';

import { useChat } from '@components/Chat/context';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './Chat.module.scss';
import Caption from './parts/Caption';
import ChatInput from './parts/ChatInput';
import ContactList from './parts/ContactList';
import Header from './parts/Header';
import Messages from './parts/Messages';
import { MessagesProvider } from './parts/Messages/context/Provider';
import NoMessages from './parts/NoMessages';

export const Chat: React.FC = () => {
    const mdUp = useBreakpoint('md', 'up');

    const { currentRoom, setRoomId, onClose } = useChat();

    const handleCloseRoom = () => {
        setRoomId(null);
    };

    return (
        <div className={style.container}>

            {((!mdUp && !currentRoom) || mdUp) && (
                <div className={style.contacts}>
                    <Caption title="Чаты" onClose={onClose} />
                    <ContactList />
                </div>
            )}

            {currentRoom && (
                <MessagesProvider>
                    <div className={style.messagesContent}>
                        <Header onClose={onClose} onBack={handleCloseRoom} />

                        <Messages  />

                        <ChatInput />
                    </div>
                </MessagesProvider>
            )}

            {mdUp && !currentRoom && <NoMessages type="messages" onClose={onClose} />}

        </div>
    );
};
