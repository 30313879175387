import React, { useRef, useEffect, useState } from 'react';

import clsx from 'classnames';

import { Antiplagiarism } from '@components/Antiplagiarism';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import { useToggle } from '@hooks/useToggle.hook';
import { IScoreMaterials } from '@type/scoreCard';

import styles from './Item.module.scss';

interface ItemProps {
    title: string;
    grade: number;
    description?: string;
    className?: string;
    material: IScoreMaterials;
}

export const Item: React.FC<ItemProps> = ({ title, grade, description, className, material }) => {
    const [isOpen, { toggle }] = useToggle(false);
    const [needMoreButton, setNeedMoreButton] = useState(false);

    const descriptionRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (descriptionRef.current) {
            const element = descriptionRef.current;

            // TODO: Тут нужно будет подумать чуть позже над лучшим решением c ререндерингом.
            if (description && description.length > 140) {
                setNeedMoreButton(true);
            }
        }
    }, [descriptionRef.current]);

    return (
        <div className={clsx(styles.item, className)}>
            <div className={styles.row}>
                <p className={styles.title}>{title}</p>
                <p className={styles.grade}>{grade} баллов</p>
            </div>

            {description && (
                <>
                    <span ref={descriptionRef} className={clsx(styles.text, styles['text-offset'], { [styles['text-overflow']]: !isOpen })}>
                        <CSVPreview content={description} />
                    </span>

                    {needMoreButton && (
                        <button className={styles.more} onClick={toggle}>
                            {!isOpen ? 'Читать полный отзыв' : 'Скрыть полный отзыв'}
                        </button>
                    )}
                </>
            )}
            {material.antiplagiarism && (
                <div style={{ marginTop: '20px' }}>
                    <Antiplagiarism col={2} result={material.antiplagiarism} file={material.antiplagiatFile} passedUniqueness={material.passedUniqueness} />
                </div>
            )}
        </div>
    );
};
